import { buildBrandedSearchRelatedCardUrlParams } from '@vakantiesnl/services/src/util/urlUtils';
import { type ContentfulLink, type LinkCard } from '@vakantiesnl/types/src';
import { useRouter } from 'next/router';

export function useLinkCardUrl(linkCard: LinkCard | ContentfulLink, departureDate?: string[] | null): string {
	const router = useRouter();

	const linkCardQueryParams = 'queryParams' in linkCard ? linkCard?.queryParams : undefined;

	const cardQueryParams = buildBrandedSearchRelatedCardUrlParams(
		linkCardQueryParams ?? '',
		router.query,
		departureDate,
	);

	let baseSlug = 'link' in linkCard ? linkCard.link : linkCard.slug;

	// todo: This can be removed as soon as no cards point to /zonvakantie anymore.
	// This is to overrule the link for cards pointing to the /zonvakantie brandedSearchTemplate/SEO entree.
	// Point to /zoeken instead because the zonvakantie campaign is no longer active.
	if (baseSlug === '/zonvakantie') {
		baseSlug = '/zoeken';
	}

	return `${baseSlug}${cardQueryParams ? cardQueryParams : ''}`;
}
