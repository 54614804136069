import { inject } from '@vakantiesnl/services/src/util/template';
import { type Search, type MicroCopy } from '@vakantiesnl/types';
import { pickBy } from 'lodash';
import { type LodgingBusiness } from 'schema-dts';

import { getTypeForLodgingBusinessSchemaItem } from './getTypeForLodgingBusinessSchemaItem';

export const getAccommodationSchemaItem = (
	accommodation: Search.FullAccommodationItem,
	origin: string,
	microCopies: MicroCopy,
	offerItem?: Search.OfferItem | Search.AccommodationOnlyOffer | null,
	accoImageURL?: string,
): LodgingBusiness => {
	const lodgingType = getTypeForLodgingBusinessSchemaItem(accommodation.type);

	const country = accommodation.location.find((location) => location.destinationType === 'COUNTRY')?.text || '';
	const region = accommodation.location.find((location) => location.destinationType === 'REGION')?.text || '';
	const city = accommodation.location.find((location) => location.destinationType === 'CITY')?.text || '';

	return pickBy({
		'@type': lodgingType,
		address: {
			'@type': 'PostalAddress',
			addressCountry: country,
			addressRegion: region,
			addressLocality: city,
			streetAddress: accommodation.contactInformation.address
				? `${accommodation.contactInformation.address.street}, ${accommodation.contactInformation.address.zipcode}`
				: null,
		},
		aggregateRating: accommodation.rating
			? {
					'@type': 'AggregateRating',
					bestRating: 10,
					worstRating: 1,
					ratingValue: accommodation.rating,
					ratingCount: accommodation.reviewCount ? accommodation.reviewCount : null,
					reviewCount: accommodation.reviewCount ? accommodation.reviewCount : null,
				}
			: null,
		geo:
			accommodation.geo.latitude && accommodation.geo.longitude
				? {
						'@type': 'GeoCoordinates',
						latitude: accommodation.geo.latitude,
						longitude: accommodation.geo.longitude,
					}
				: null,
		image: accoImageURL ? accoImageURL : null,
		name: accommodation.name,
		starRating: accommodation.starRating,
		telephone: accommodation.contactInformation ? accommodation.contactInformation.phone : null,
		url: `${origin}${accommodation.url}`,
		priceRange: offerItem
			? inject(microCopies['booking.schema.priceRange'], {
					price: String(Math.round(offerItem?.pricePerAdult / 100)),
					duration: String(offerItem.durationDays),
				}).join('')
			: null,
	}) as unknown as LodgingBusiness;
};
