import { getMinutesInSec } from '@vakantiesnl/services/src/util';

export const checkCache = async <T>(key: string, hasLogsEnabled = false): Promise<T | undefined> => {
	if (typeof window === 'undefined') {
		// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
		const { getRedisClient } = require('@vakantiesnl/services/src/redis/client');
		try {
			const client = await getRedisClient();
			if (client.status === 'ready') {
				const rawData = await client.get(`next:v1:${key}`);
				if (rawData) {
					if (hasLogsEnabled) {
						console.log(`Retrieved data from Redis with key ${key}`);
					}
					return JSON.parse(rawData);
				} else {
					if (hasLogsEnabled) {
						console.log(`No Redis entree found with key ${key}`);
					}
				}
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('Error fetching Redis cache: ', err);
		}
	}
};

const DEFAULT_REDIS_CACHE_TIME = getMinutesInSec(15);

export const storeInCache = async (
	key: string,
	data: Record<string, unknown>,
	cacheTimeInMs?: number,
	hasLogsEnabled = false,
): Promise<void> => {
	if (typeof window === 'undefined') {
		try {
			// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
			const { getRedisClient } = require('@vakantiesnl/services/src/redis/client');
			const client = await getRedisClient();
			// Expire in 10 minutes
			if (client.status === 'ready') {
				await client.setex(`next:v1:${key}`, cacheTimeInMs ?? DEFAULT_REDIS_CACHE_TIME, JSON.stringify(data));
				if (hasLogsEnabled) {
					console.log(`Stored data in Redis with key ${key}`);
				}
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('Error storing data in Redis cache: ', err);
		}
	}
};
