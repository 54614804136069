export const getTypeForLodgingBusinessSchemaItem = (accoType: string | undefined): string => {
	let type = 'LodgingBusiness';

	switch (accoType) {
		default:
			type = 'LodgingBusiness';
			break;

		case 'bed-and-breakfast':
			type = 'BedAndBreakfast';
			break;

		case 'appartement':
			type = 'Resort';
			break;

		case 'hotel':
			type = 'Hotel';
			break;

		case 'hostel':
			type = 'Hostel';
			break;

		case 'camping':
			type = 'Campground';
			break;
	}

	return type;
};
